<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-in-tab>
      <grid item-min-width="49.5%">
        <aging-summary :account="account" />
        <escalation-summary
          :account="account"
          :accountId="$route.params.id"
          @refresh="fetch()"
        />
        <balance-summary :account="account" />
        <sales-payments-summary :account="account" />
        <dispute-summary :account="account" />
        <promises-summary
          v-if="account"
          :promisesData="account.promises"
        />
        <kpi :account="account" />
      </grid>
    </document-in-tab>
  </div>
</template>

<script>

export default {
  components: {
    AgingSummary: () => import('./aging/aging-summary'),
    BalanceSummary: () => import('./balance/balance-summary'),
    EscalationSummary: () => import('./escalation-summary'),
    DisputeSummary: () => import('./dispute/dispute-summary'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    Grid: () => import('@/components/grid'),
    Kpi: () => import('./kpi/kpi'),
    PromisesSummary: () => import('@/components/kpi/promises-summary'),
    SalesPaymentsSummary: () => import('./sales-payments-summary')
  },
  data () {
    return {
      account: null
    }
  },
  methods: {
    async fetch () {
      const accountId = this.$route.params.id
      if (!accountId) { return }
      const e = await this.$http().versionHashed().get('core/v6/accounts/' + accountId + '/summary')
      this.account = e.data
    }
  },
  activated () {
    this.fetch()
  }
}
</script>
